export const data = [
    {
        id: 1,
        name: "Testimonial 1 Name",
        review: "Testimonial 1 Text"
    },
    {
        id: 2,
        name: "Testimonial 2 Name",
        review: "Testimonial 2 Text"
    },
    {
        id: 3,
        name: "Testimonial 3 Name",
        review: "Testimonial 3 Text"
    },
    {
        id: 4,
        name: "Testimonial 4 Name",
        review: "Testimonial 4 Text"
    },
    {
        id: 5,
        name: "Testimonial 5 Name",
        review: "Testimonial 5 Text"
    },
    {
        id: 6,
        name: "Testimonial 6 Name",
        review: "Testimonial 6 Text"
    },
    {
        id: 7,
        name: "Testimonial 7 Name",
        review: "Testimonial 7 Text"
    },
    {
        id: 8,
        name: "Testimonial 8 Name",
        review: "Testimonial 8 Text"
    },
    {
        id: 9,
        name: "Testimonial 9 Name",
        review: "Testimonial 9 Text"
    },
    {
        id: 10,
        name: "Testimonial 10 Name",
        review: "Testimonial 10 Text"
    },
    {
        id: 11,
        name: "Testimonial 11 Name",
        review: "Testimonial 11 Text"
    }
]