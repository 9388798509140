import img1 from '../../../shared/assets/statistic/IMG_8391.webp'
import img2 from '../../../shared/assets/statistic/IMG_8392.webp'
import img3 from '../../../shared/assets/statistic/IMG_8393.webp'
import img4 from '../../../shared/assets/statistic/IMG_8394.webp'

export const data =[
    {
        id: 1,
        image: img1,
    },
    {
        id: 2,
        image: img2,
    },
    {
        id: 3,
        image: img3,
    },
    {
        id:4,
        image: img4,
    },
]