export const data = [ 
    {
        id: 1,
        name: "Review Name 1",
        review: "Review Text 1"
    },
    {
        id: 2,
        name: "Review Name 2",
        review: "Review Text 2"
    },
    {
        id: 3,
        name: "Review Name 3",
        review: "Review Text 3"
    },
    {
        id: 4,
        name: "Review Name 4",
        review: "Review Text 4"
    },
    {
        id: 5,
        name: "Review Name 5",
        review: "Review Text 5"
    },
    {
        id: 6,
        name: "Review Name 6",
        review: "Review Text 6"
    },
]