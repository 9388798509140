import sponsor1 from '../../../shared/assets/sponsors/webp/sponsor.webp';
import sponsor2 from '../../../shared/assets/sponsors/webp/sponsor1.webp';
import sponsor3 from '../../../shared/assets/sponsors/sponsor11.jpeg';
import sponsor4 from '../../../shared/assets/sponsors/webp/sponsor3.webp';
// import sponsor5 from '../../../shared/assets/sponsors/webp/sponsor4.webp';
import sponsor6 from '../../../shared/assets/sponsors/webp/sponsor5.webp';
import sponsor8 from '../../../shared/assets/sponsors/webp/sponsor7.webp';
import sponsor9 from '../../../shared/assets/sponsors/webp/sponsor8.webp';
import sponsor10 from '../../../shared/assets/sponsors/webp/sponsor9.webp';
import sponsor11 from '../../../shared/assets/sponsors/webp/sponsor10.webp';
import sponsor12 from '../../../shared/assets/sponsors/webp/sponsor11.webp';
import sponsor13 from '../../../shared/assets/sponsors/webp/sponsor12.jpeg';
import sponsor14 from '../../../shared/assets/sponsors/webp/sponsor13.webp';
import sponsor15 from '../../../shared/assets/sponsors/webp/kerl.webp';
import sponsor16 from '../../../shared/assets/sponsors/webp/nagi.webp';
import sponsor20 from '../../../shared/assets/sponsors/sponsor-20.jpg';
import sponsor21 from '../../../shared/assets/sponsors/sponsor-21.jpg';
import sponsor22 from '../../../shared/assets/sponsors/sponsor-22.jpg';
import sponsor23 from '../../../shared/assets/sponsors/sponsor-23.jpg';
import sponsor24 from '../../../shared/assets/sponsors/sponsor-24.jpg';
import sponsor25 from '../../../shared/assets/sponsors/sponsor-25.jpg';
import sponsor26 from '../../../shared/assets/sponsors/sponsor-26.jpg';
import sponsor27 from '../../../shared/assets/sponsors/sponsor-27.jpg';
import sponsor28 from '../../../shared/assets/sponsors/sponsor-28.jpg';
import sponsor29 from '../../../shared/assets/sponsors/sponsor-29.jpg';
import sponsor30 from '../../../shared/assets/sponsors/sponsor-30.jpg';
import sponsor31 from '../../../shared/assets/sponsors/sponsor-31.jpg';
import sponsor32 from '../../../shared/assets/sponsors/sponsor-32.jpg';
import sponsor33 from '../../../shared/assets/sponsors/sponsor-33.jpg';
import sponsor34 from '../../../shared/assets/sponsors/sponsor-34.jpg';
import sponsor35 from '../../../shared/assets/sponsors/sponsor-35.jpg';




export const data = [
    {
        img: sponsor13,
        name:'English teacher',
    },
    {
        img: sponsor2,
        name:'Vera-Art',
    },
    {
        img: sponsor9,
        name:'NailCocktail',
    },
    {
        img: sponsor16,
        name:'Nagi ',
        name2: 'Raw organic energy bars'
    },
    {
        img: sponsor14,
        name:'Stylist-buyer ',
        name2: 'Anna Moroz'
    },
    {
        img: sponsor4,
        name:'Galeriana gifts',
    },
    {
        img: sponsor3,
        name:'do Terra',
        name2: 'essential oils'
    },
    {
        img: sponsor8,
        name:'Maria Damian',
        name2: 'photo'
    },
    {
        img: sponsor1,
        name:'Moon Rivers ',
        name2: 'Fashion' 
    },
    {
        img: sponsor15,
        name:'Kert beauty',
    },
    {
        img: sponsor10,
        name:'The Flamingo Collective',
    },
    {
        img: sponsor12,
        name: 'Atomy',
    },
    {
        img: sponsor11,
        name:'Bloom events',
    },
    // {
    //     img: sponsor5,
    //     name:'Love your body coach',
    // },
    {
        img: sponsor6,
        name:"Aster's cake",
    },
    {
        img: sponsor20,
        name:"",
    },
    {
        img: sponsor21,
        name:"",
    },
    {
        img: sponsor22,
        name:"",
    },
    {
        img: sponsor23,
        name:"",
    },
    {
        img: sponsor24,
        name:"",
    },
    {
        img: sponsor25,
        name:"",
    },
    {
        img: sponsor26,
        name:"",
    },
    {
        img: sponsor27,
        name:"",
    },
    {
        img: sponsor28,
        name:"",
    },
    {
        img: sponsor29,
        name:"",
    },
    {
        img: sponsor30,
        name:"",
    },
    {
        img: sponsor31,
        name:"",
    },
    {
        img: sponsor32,
        name:"",
    },
    {
        img: sponsor33,
        name:"",
    },
    {
        img: sponsor34,
        name:"",
    },
    {
        img: sponsor35,
        name:"",
    },
]
