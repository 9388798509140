export const data =[
    {
        list: 'TC List 1 Title',
        description: 'TC List Description 1'
    },
    {
        list: 'TC List 2 Title',
        description: 'TC List Description 2'
    },
    {
        list: 'TC List 3 Title',
        description: 'TC List Description 3'
    },
    {
        list: 'TC List 4 Title',
        description: 'TC List Description 4'
    },
    {
        list: 'TC List 5 Title',
        description: 'TC List Description 5'
    },
    {
        list: 'TC List 6 Title',
        description: 'TC List Description 6'
    },
    {
        list: 'TC List 7 Title',
        description: 'TC List Description 7'
    },
    {
        list: 'TC List 8 Title',
        description: 'TC List Description 8'
    },
    {
        list: 'TC List 9 Title',
        description: 'TC List Description 9'
    },
]