export const data =[
    {
        title: "PR List 1 Title",
        text: "PR List Description 1",
        list: [
            "PR List Description 1a",
            "PR List Description 1b"
        ]
    },
    {
        title: "PR List 2 Title",
        text: "PR List Description 2",
        list: [
            "PR List Description 2a",
            "PR List Description 2b",
            "PR List Description 2c"
        ]
    },
    {
        title: "PR List 3 Title",
        text: "PR List Description 3",
        list: []
    },
    {
        title: "PR List 4 Title",
        text: "PR List Description 4",
        list: []
    },
    {
        title: "PR List 5 Title",
        text: "PR List Description 5",
        list: []
    },
    {
        title: "PR List 6 Title",
        text: "PR List Description 6",
        list: []
    },
    {
        title: "PR List 7 Title",
        text: "PR List Description 7",
        list: []
    },
]