import image1 from '../../../shared/assets/images/webp/3X2A0446.jpg';
import image2 from '../../../shared/assets/images/webp/initial-photo.webp';
import image3 from '../../../shared/assets/images/webp/6.webp';
import image4 from '../../../shared/assets/images/webp/7.webp';
import image5 from '../../../shared/assets/images/webp/5.webp';
import image6 from '../../../shared/assets/images/webp/2.webp';
import image7 from '../../../shared/assets/images/webp/3.webp';
import image8 from '../../../shared/assets/images/webp/8.webp';
import image9 from '../../../shared/assets/images/webp/9.webp';
import image10 from '../../../shared/assets/images/webp/11.webp';
import image11 from '../../../shared/assets/images/webp/12.webp';
import image12 from '../../../shared/assets/images/webp/13.webp';
import image13 from '../../../shared/assets/images/webp/14.webp';
import image14 from '../../../shared/assets/images/webp/15.webp';
import image15 from '../../../shared/assets/images/webp/16.webp';
import image16 from '../../../shared/assets/images/webp/17.webp';
import image17 from '../../../shared/assets/images/webp/18.webp';
import image18 from '../../../shared/assets/images/webp/20.webp';
import image19 from '../../../shared/assets/images/webp/21.webp';
import image20 from '../../../shared/assets/images/webp/22.webp';
import image21 from '../../../shared/assets/images/webp/23.webp';
import image22 from '../../../shared/assets/images/webp/3X2A9615.jpg';
import image23 from '../../../shared/assets/images/webp/25.webp';
// import image24 from '../../../shared/assets/images/webp/26.webp';
import image25 from '../../../shared/assets/images/webp/29.webp';
import image26 from '../../../shared/assets/images/30.jpg';
import image27 from '../../../shared/assets/images/31.jpg';
import image28 from '../../../shared/assets/images/32.jpg';
import image29 from '../../../shared/assets/images/33.jpg';
import image30 from '../../../shared/assets/images/34.jpg';
import image31 from '../../../shared/assets/images/35.jpg';
import image32 from '../../../shared/assets/images/36.jpg';
import image33 from '../../../shared/assets/images/37.jpg';

export const data = [
    {
        id: 1,
        img: image1,
    },
    {
        id: 1,
        img: image2,
    },
    {
        id: 1,
        img: image3,
    },
    {
        id: 1,
        img: image4,
    },
    {
        id: 1,
        img: image5,
    },
    {
        id: 1,
        img: image6,
    },
    {
        id: 1,
        img: image7,
    },
    {
        id: 1,
        img: image8,
    },
    {
        id: 1,
        img: image9,
    },
    {
        id: 1,
        img: image10,
    },
    {
        id: 1,
        img: image11,
    },
    {
        id: 1,
        img: image12,
    },
    {
        id: 1,
        img: image13,
    },
    {
        id: 1,
        img: image14,
    },
    {
        id: 1,
        img: image15,
    },
    {
        id: 1,
        img: image16,
    },
    {
        id: 1,
        img: image17,
    },
    {
        id: 1,
        img: image18,
    },
    {
        id: 1,
        img: image19,
    },
    {
        id: 1,
        img: image20,
    },
    {
        id: 1,
        img: image21,
    },
    {
        id: 1,
        img: image22,
    },
    {
        id: 1,
        img: image23,
    },
    // {
    //     id: 1,
    //     img: image24,
    // },
    {
        id: 1,
        img: image25,
    },
    {
        id: 1,
        img: image26,
    },
    {
        id: 1,
        img: image27,
    },
    {
        id: 1,
        img: image28,
    },
    {
        id: 1,
        img: image29,
    },
    {
        id: 1,
        img: image30,
    },
    {
        id: 1,
        img: image31,
    },
    {
        id: 1,
        img: image32,
    },
    {
        id: 1,
        img: image33,
    },
]