import imageEvent1 from '../../../shared/assets/images/IMG_8363.jpg';
import imageEvent2 from '../../../shared/assets/images/IMG_8361.jpg';
import imageEvent3 from '../../../shared/assets/images/webp/3X2A1929.webp';
import imageEvent4 from '../../../shared/assets/images/webp/3X2A1939.webp';
import imageEvent5 from '../../../shared/assets/images/webp/3X2A1263.webp';
import imageEvent6 from '../../../shared/assets/images/webp/3X2A1041.webp';



export const data = {
    imageEvent1,
    imageEvent2,
    imageEvent3,
    imageEvent4,
    imageEvent5,
    imageEvent6,
}